import { useEffect } from 'react';

const $ = window.jQuery;

export const useMenuDropdown = (load: boolean) => {
  useEffect(() => {
    if (load) {
      $(document).on('click', '#moreMenuOuter', function (this: any, e: number) {
        $(this).parents('.h_menu_link').toggleClass('active');
        $('.menuDrop').slideToggle();
      });

    }
  }, [load]);
};
