import { generalURLs } from '../../constants/urls';
import { handleFetchResponse } from '../../helpers/handleFetchResponse';
import { getConfig, postConfig } from '../fetchConfigurations';
import {
  AllMerchantRequestType,
  AllMerchantResponseType,
  AllMerchantType,
  AreaListRequestType,
  AreaListResponseType,
  AreaListType,
  BudgetFilterRequestType,
  BudgetFilterResponseType,
  BudgetFilterType,
  CategoryOffersRequestType,
  CategoryOffersResponseType,
  CategoryOffersType,
  CitiesListForFiltersRequestType,
  CitiesListForFiltersResponseType,
  CitiesListForFiltersType,
  CityListRequestType,
  CityListResponseType,
  CityListType,
  DashboardRequestType,
  DashboardResponseType,
  DashboardType,
  DiscountFilterRequestType,
  DiscountFilterResponseType,
  DiscountFilterType,
  DistanceFilterRequestType,
  DistanceFilterResponseType,
  DistanceFilterType,
  FilterSubClassRequestType,
  FilterSubClassResponseType,
  FilterSubClassType,
  GetBannerRequestType,
  GetBannerResponseType,
  GetBannerType,
  MerchantAllOfferRequestType,
  MerchantAllOfferResponseType,
  MerchantAllOfferType,
  MerchantDetailsRequestType,
  MerchantDetailsResponseType,
  MerchantDetailsType,
  OfferDetailsRequestType,
  OfferDetailsResponseType,
  OfferDetailsType,
  SavingFilterRequestType,
  SavingFilterResponseType,
  SavingFilterType,
  SearchRequestType,
  SearchResponseType,
  SearchType,
  StatesListResponseType,
  StatesListType,
  SubCategoryOffersRequestType,
  SubCategoryOffersResponseType,
  SubCategoryOffersType,
  TermsAndFAQsRequestType,
  TermsAndFAQsType,

  MerchantTermsAndFAQsResponseType,
  CardRequestResponseType,
  CardRequestType,

} from './generalAPIsTypes';

export const getTopBanners: GetBannerType = (payload: GetBannerRequestType) =>
  fetch(generalURLs.topBanner, postConfig(payload)).then<GetBannerResponseType>(
    handleFetchResponse
  );

export const getDiscountFilter: DiscountFilterType = (
  payload: DiscountFilterRequestType
) =>
  fetch(
    generalURLs.discountFilter,
    postConfig(payload)
  ).then<DiscountFilterResponseType>(handleFetchResponse);

export const getCityList: CityListType = (payload: CityListRequestType) =>
  fetch(generalURLs.cityList, postConfig(payload)).then<CityListResponseType>(
    handleFetchResponse
  );

export const getAreaList: AreaListType = (payload: AreaListRequestType) =>
  fetch(generalURLs.areaList, postConfig(payload)).then<AreaListResponseType>(
    handleFetchResponse
  );

export const getDistanceFilter: DistanceFilterType = (
  payload: DistanceFilterRequestType
) =>
  fetch(
    generalURLs.distanceFilter,
    postConfig(payload)
  ).then<DistanceFilterResponseType>(handleFetchResponse);

export const getBudgetFilter: BudgetFilterType = (
  payload: BudgetFilterRequestType
) =>
  fetch(
    generalURLs.budgetFilter,
    postConfig(payload)
  ).then<BudgetFilterResponseType>(handleFetchResponse);

export const getSavingFilter: SavingFilterType = (
  payload: SavingFilterRequestType
) =>
  fetch(
    generalURLs.savingFilter,
    postConfig(payload)
  ).then<SavingFilterResponseType>(handleFetchResponse);

export const getCategoryOffers: CategoryOffersType = (
  payload: CategoryOffersRequestType
) =>
  fetch(
    generalURLs.categoryOffers,
    postConfig(payload)
  ).then<CategoryOffersResponseType>(handleFetchResponse);

export const getSubCategoryOffers: SubCategoryOffersType = (
  payload: SubCategoryOffersRequestType
) =>
  fetch(
    generalURLs.subCategoryOffers,
    postConfig(payload)
  ).then<SubCategoryOffersResponseType>(handleFetchResponse);

export const getOfferDetails: OfferDetailsType = (
  payload: OfferDetailsRequestType
) =>
  fetch(
    generalURLs.offerDetails,
    postConfig(payload)
  ).then<OfferDetailsResponseType>(handleFetchResponse);

export const getMerchantDetails: MerchantDetailsType = (
  payload: MerchantDetailsRequestType
) =>
  fetch(
    generalURLs.merchantDetails,
    postConfig(payload)
  ).then<MerchantDetailsResponseType>(handleFetchResponse);

export const getMerchantAllOffer: MerchantAllOfferType = (
  payload: MerchantAllOfferRequestType
) =>
  fetch(
    generalURLs.merchantAllOffer,
    postConfig(payload)
  ).then<MerchantAllOfferResponseType>(handleFetchResponse);

export const search: SearchType = (payload: SearchRequestType) =>
  fetch(generalURLs.search, postConfig(payload)).then<SearchResponseType>(
    handleFetchResponse
  );

export const allMerchant: AllMerchantType = (payload: AllMerchantRequestType) =>
  fetch(
    generalURLs.allMerchant,
    postConfig(payload)
  ).then<AllMerchantResponseType>(handleFetchResponse);

export const getDashboard: DashboardType = (payload: DashboardRequestType) =>
  fetch(generalURLs.dashboard, postConfig(payload)).then<DashboardResponseType>(
    handleFetchResponse
  );

export const filterSubClass: FilterSubClassType = (
  payload: FilterSubClassRequestType
) =>
  fetch(
    generalURLs.filterSubClass,
    postConfig(payload)
  ).then<FilterSubClassResponseType>(handleFetchResponse);

export const statesList: StatesListType = () =>
  fetch(generalURLs.statesList, getConfig).then<StatesListResponseType>(
    handleFetchResponse
  );

export const termsAndFAQs: TermsAndFAQsType = (
  payload: TermsAndFAQsRequestType
) =>
  fetch(generalURLs.termsAndFAQs, postConfig(payload)).then(
    handleFetchResponse
  );

export const merchantTermsAndFAQs = () =>
  fetch(generalURLs.merchanttermsAndFAQs, getConfig).then<MerchantTermsAndFAQsResponseType>(
    handleFetchResponse
  );

export const citiesListForFilters: CitiesListForFiltersType = (
  payload: CitiesListForFiltersRequestType
) =>
  fetch(
    generalURLs.citiesListForFilters,
    postConfig(payload)
  ).then<CitiesListForFiltersResponseType>(handleFetchResponse);

export const getLeadCardRequest: CardRequestType = (offerId: number, userId: number) =>
  fetch(generalURLs.getLeadCardRequest + "?offerId=" + offerId + "&userId=" + userId, getConfig).then<CardRequestResponseType>(

    handleFetchResponse
  );

export const getSuperCardRequest: CardRequestType = (offerId: number, userId: number) =>
  fetch(generalURLs.getSuperCardRequest + "?offerId=" + offerId + "&userId=" + userId, getConfig).then<CardRequestResponseType>(
    handleFetchResponse
  );