import { useEffect } from 'react';

const $ = window.jQuery;

export const useUserDropdown = (load: boolean) => {
  useEffect(() => {
    if (load) {
      $('.account_outer').click(function (this: any, e: number) {
        $(this).parents('.h_account_link').toggleClass('active');
        $('.userDrop').slideToggle();
      });
      $('#sign-in-button').click(function (this: any, e: number) {
        $(this).parents('.h_account_link').toggleClass('active');
        $('.userDrop').hide();
      });
      $('#logout-button').click(function (this: any, e: number) {
        $(this).parents('.h_account_link').toggleClass('active');
        $('.userDrop').hide();
      });
      $(document).mouseup(function (e: any) {
        var container = $('.userDrop,.h_account_link');
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          $('.h_account_link').removeClass('active');
          $('.userDrop').slideUp();
        }
      });
    }
  }, [load]);
};
