import { Suspense, useEffect } from 'react';
import Fallback from '../components/Fallback';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { PublicRoutesType } from '../constants/publicRoutes';
import { setSearchTerm } from '../redux/reducers/searchReducer';
import { useAppDispatch } from '../redux/storeCustomHooks';

const $ = window.jQuery;

type Props = {
  data: PublicRoutesType[number];
};

const PublicRoute = ({ data }: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data.path !== '/search') {
      dispatch(setSearchTerm(''));
    }
    document.title = data.title ? data.title : 'Foorum';
    window.scrollTo(0, 0);
    $('.userDrop').slideUp();
  }, [data, dispatch]);

  return (
    <Suspense fallback={<Fallback />}>
      <div>
        <Header />
        {data.element}
        <Footer />
      </div>
    </Suspense>
  );
};

export default PublicRoute;
