import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserVerifyOTPResponseType } from '../../APIs/userAPIs/userAPIsTypes';
import { RootState } from '../store';

type InitialStateTypes = {
  data?: UserVerifyOTPResponseType;
  location: { lat: number; lng: number };
};

let initialLocation: { lat: number; lng: number } = { lat: 0, lng: 0 };

// Define the initial state using that type
let initialState: InitialStateTypes = {
  data: undefined,
  location: initialLocation,
};

const user = localStorage.getItem('user');

if (typeof user === 'string') {
  initialState = { data: JSON.parse(user), location: initialLocation };
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserVerifyOTPResponseType>) => {
      localStorage.clear();
      localStorage.setItem('user', JSON.stringify(action.payload));
      state.data = action.payload;
    },
    setUserLocation: (
      state,
      action: PayloadAction<{ lat: number; lng: number }>
    ) => {
      state.location = action.payload;
    },
    logout: (state, action: PayloadAction) => {
      localStorage.clear();
      state.data = undefined;
    },
  },
});

export const { setUser, setUserLocation, logout } = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectUser = (state: RootState) => state.user.data;
export const selectUserLocation = (state: RootState) => state.user.location;

export default userSlice.reducer;
