import { Routes, Route } from 'react-router-dom';
import { privateRoutes } from '../constants/privateRoutes';
import { publicRoutes } from '../constants/publicRoutes';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

interface Props {}

const Router = (props: Props) => {
  return (
    <Routes>
      {publicRoutes.map(data => {
        return (
          <Route
            key={data.path}
            path={data.path}
            element={<PublicRoute {...{ data }} />}
          />
        );
      })}
      {privateRoutes.map(data => {
        return (
          <Route
            key={data.path}
            path={data.path}
            element={<PrivateRoute {...{ data }} />}
          />
        );
      })}
    </Routes>
  );
};

export default Router;
