type HandleResponseType = {
  <T>(response: Response): Promise<T>;
};
type HandleResponseTextType = {
  (response: Response): Promise<string>;
};

export const handleFetchResponse: HandleResponseType = response => {
  const contentType = response.headers.get('content-type');
  if (
    response.ok &&
    contentType &&
    contentType.indexOf('application/json') !== -1
  ) {
    return response.json();
  }
  throw new Error(
    JSON.stringify({
      status: response.status,
      statusText: response.statusText,
    })
  );
};

export const handleFetchTextResponse: HandleResponseTextType = response => {
  if (response.ok) {
    return response.text();
  }
  throw new Error(
    JSON.stringify({
      status: response.status,
      statusText: response.statusText,
    })
  );
};
