import { useEffect } from 'react';

const $ = window.jQuery;

export const useNavigationUIComponents = (load: boolean) => {
  useEffect(() => {
    if (load) {
      $('.toggleMenu').click(function () {
        $('.menu,.pageOverlay').addClass('active');
        $('body').css('overflow', 'hidden');
      });
      // $('.overlay,.closeMenu').click(function (e: any) {
      //   $('.menu,.overlay').removeClass('active');
      //   $('body').css('overflow-y', 'auto');
      // });
      // $('.toggleMenu').mouseenter(function (e: any) {
      //   $('.menu,.pageOverlay').addClass('active');
      //   $('body').css('overflow', 'hidden');
      // });
      $('.hasmenu > a').mouseenter(function (this: any, e: any) {
        if ($(window).width() > 991) {
          var thisOffsetRight = $(this).offset().left;
          var windowWidth = $(window).width();
          thisOffsetRight = windowWidth - thisOffsetRight;
          var submenuWidth = $(this)
            .parents('li')
            .find('.submenu')
            .outerWidth();
          if (thisOffsetRight < submenuWidth) {
            $(this)
              .parents('li')
              .find('.submenu')
              .css({ left: 'auto', right: '0px' });
          }
        }
      });
      $('.openMenu').click(function (this: any, e: any) {
        if (
          $(this).parents('.hasmenu').find('.submenu').css('display') === 'none'
        ) {
          $('.openMenu').removeClass('active');
          $(this).addClass('active');
          $('.submenu').slideUp();
          $(this).parents('.hasmenu').find('.submenu').slideDown();
        } else {
          $('.openMenu').removeClass('active');
          $('.submenu').slideUp();
        }
      });
    }
  }, [load]);
};
