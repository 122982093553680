import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import { useCallback, useEffect } from "react";
import { getAreaList, getCityList } from "./APIs/generalAPIs/generalAPIs";
import { AreaListRequestType } from "./APIs/generalAPIs/generalAPIsTypes";
import { getCategories } from "./APIs/userAPIs/userAPIs";
import { UserCategoryListRequestType } from "./APIs/userAPIs/userAPIsTypes";
import "./App.css";
import { useCategories } from "./helpers/customHooks/useCategories";
import { useCities, useCityId } from "./helpers/customHooks/useCities";
import { handleError } from "./helpers/handleError";
import { handleResponseInComponent } from "./helpers/handleResponseInComponent";
import { setCategories } from "./redux/reducers/categoriesReducer";
import {
  setAreaList,
  setCities,
  setCityId,
} from "./redux/reducers/citiesReducer";
import { setUserLocation } from "./redux/reducers/userReducer";
import { useAppDispatch } from "./redux/storeCustomHooks";
import Router from "./router/Router";

const $ = window.jQuery;

export type SortTypes =
  | "most-recent"
  | "top-rated"
  | "price-low-to-high"
  | "price-high-to-low"
  | "discount-high-to-low"
  | "discount-low-to-high"
  | "distance-near-to-far"
  | "saving-high-to-low";

function App() {
  const dispatch = useAppDispatch();

  const cityList = useCities();
  const cityId = useCityId();
  const categoryList = useCategories();

  // fetching categories and storing in redux
  useEffect(() => {
    getCityList({})
      .then(
        handleResponseInComponent((data) => {
          dispatch(setCities(data));
          if (!localStorage.getItem("cityId")) {
            const defaultCity = data.Result.cityList?.find(
              (city) => city.city === "New Delhi"
            )?.id;
            defaultCity && dispatch(setCityId(defaultCity));
          }
        })
      )
      .catch(handleError());
  }, [dispatch]);

  useEffect(() => {
    if (cityId) {
      const payload: UserCategoryListRequestType = {
        cityId: `${cityId}`,
      };
      getCategories(payload)
        .then(
          handleResponseInComponent((data) => {
            dispatch(setCategories(data));
          })
        )
        .catch(handleError());
    }
  }, [dispatch, cityId]);

  const fetchAreaList = useCallback(
    (cityId: number) => {
      const payload: AreaListRequestType = {
        cityId: `${cityId}`,
      };
      getAreaList(payload)
        .then(
          handleResponseInComponent(
            (data) => {
              // actual data processing is happening in the redux reducer
              // see citiesReducer for details
              dispatch(setAreaList({ data: data.Result.area_list, cityId }));
            },
            undefined,
            true
          )
        )
        .catch(handleError(undefined, undefined, true));
    },
    [dispatch]
  );

  useEffect(() => {
    if (cityList && cityList?.Result.cityList.length > 0) {
      cityList?.Result.cityList.forEach((city) => {
        fetchAreaList(city.id);
      });
    }
  }, [fetchAreaList, cityList]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      dispatch(
        setUserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })
      );
    });
  }, [dispatch]);

  const topMargin = useCallback(() => {
    var topHeight = $("header").outerHeight();
    $(".mt-160").css("margin-top", topHeight + "px");
  }, []);

  const handleScroll = useCallback(function () {
    var headerHeight;
    // offsetTop = $('.filter_outer').offset().top;
    headerHeight = $("header").outerHeight();
    if ($(window).scrollTop() >= headerHeight + 400) {
      $(".filter_outer").addClass("active");
      $(".filter_outer").css("top", headerHeight + "px");
    } else {
      $(".filter_outer").removeClass("active");
      $(".filter_outer").css("top", "0px");
    }

    // var offsetTop, headerHeight;
    // offsetTop = $(".filter_outer").offset().top;
    // headerHeight = $("header").outerHeight();
    // var offsetTopDiff = offsetTop - headerHeight;
    // if ($(window).scrollTop() >= offsetTopDiff) {
    //   $(".filter_outer").addClass("active");
    //   $(".filter_outer").css("top", headerHeight + 'px');
    // }
    // else {
    //   $(".filter_outer").removeClass("active");
    //   $(".filter_outer").css("top", "0px");
    // }
  }, []);

  useEffect(() => {
    topMargin();
    window.addEventListener("resize", topMargin);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("resize", topMargin);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [topMargin, categoryList, cityId, handleScroll]);

  return <Router />;
}

export default App;
