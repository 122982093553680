import React from "react";

interface Props {}

const Marchants = (props: Props) => {
  return (
    <div className="footer_item">
      <h4>Merchants</h4>
      <ul className="link_outer">
        <li>
          <a href="https://backend.bigsocial.in/partner">Sign In</a>
        </li>
        <li>
          <a href="/register-business">Register your business</a>
        </li>
        <li>
          <a href="/why-bigsocial">Why Bigsocial</a>
        </li>
        <li>
          <a href="/managing-your-account">Managing your Account</a>
        </li>
        <li>
          <a href="/terms-of-business">Terms of Business</a>
        </li>
        <li>
          <a href="/merchant-privacy-policy">Privacy Policy</a>
        </li>
        <li>
          <a href="/general-agreement">General Agreement</a>
        </li>
        <li>
          <a href="/merchant-faqs">FAQs</a>
        </li>
        <li>
          <a href="/bigsocial-campaign">Run a Bigsocial campaign</a>
        </li>
        <li>
          <a href="/advertise">Advertise with us</a>
        </li>

        <li>
          <a href="/coming-soon">Servitization Opportunities</a>
        </li>
        <li>
          <a href="/coming-soon">Growth Consulting</a>
        </li>
        <li>
          <a href="/coming-soon">Business Analytics</a>
        </li>
        <li>
          <a href="/coming-soon">Integrated Marketing</a>
        </li>
      </ul>
    </div>
  );
};

export default Marchants;
