import React, { useCallback, useState } from 'react';
import { login, verifyOTP } from '../APIs/userAPIs/userAPIs';
import {
  UserLoginRequestType,
  UserVerifyOTPRequestType,
} from '../APIs/userAPIs/userAPIsTypes';
import { customSwal } from '../helpers/customSwal';
import { handleError } from '../helpers/handleError';
import { handleResponseInComponent } from '../helpers/handleResponseInComponent';
import { setUser } from '../redux/reducers/userReducer';
import { useAppDispatch } from '../redux/storeCustomHooks';

const $ = window.jQuery;

interface Props {
  signProcess: 'signIn' | 'signUp';
}

const LoginOrRegisterModal = ({ signProcess }: Props) => {
  const dispatch = useAppDispatch();

  const [step, setStep] = useState<'otp' | 'sign-in'>('otp');

  const [mobile, setMobile] = useState('');
  const [otp, setOtp] = useState('');

  const handleOtp = useCallback(() => {
    const payload: UserLoginRequestType = {
      mobileNo: mobile,
      appVersion: '1.0',
      deviceVersion: '10.0',
      deviceType: 'Web app',
    };
    login(payload)
      .then(
        handleResponseInComponent(data => {
          setStep('sign-in');
        })
      )
      .catch(handleError());
  }, [mobile]);

  const handleVerifyOTP = useCallback(() => {
    const payload: UserVerifyOTPRequestType = new FormData();
    payload.append('mobileNo', mobile);
    payload.append('otp', otp);
    verifyOTP(payload)
      .then(
        handleResponseInComponent(data => {
          $('#loginRegisterModal').modal('hide');
          dispatch(setUser(data));
          // customSwal('Success', 'Logged in successfully!', 'success');
        })
      )
      .catch(handleError());
  }, [mobile, otp, dispatch]);

  return (
    <div className="modal fade" id="loginRegisterModal">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <div className="appbody">
              <button type="button" className="close" data-dismiss="modal">
                <img
                  src={process.env.PUBLIC_URL + '/assets/images/icon-close.png'}
                  alt=""
                  style={{ height: 25, width: 25 }}
                />
              </button>
              <label className="mb-4">
                <h3>{signProcess === 'signIn' ? 'Sign In' : 'Sign Up'}</h3>
              </label>
              {step === 'otp' && (
                <>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your phone number..."
                    name=""
                    value={mobile}
                    onChange={e =>
                      setMobile(prevValue => {
                        if (/^\d*$/.test(e.target.value)) {
                          return e.target.value;
                        } else {
                          customSwal(
                            '',
                            'Only numbers are allowed!',
                            'warning'
                          );
                        }
                        return prevValue;
                      })
                    }
                  />
                  <div className="sign-in">
                    <button
                      className="btn btn-all btn-pink"
                      onClick={handleOtp}
                    >
                      Send OTP
                    </button>
                  </div>
                </>
              )}
              {step === 'sign-in' && (
                <>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your OTP here..."
                    name=""
                    value={otp}
                    onChange={e => setOtp(e.target.value)}
                  />
                  <small>An OTP was sent to your mobile no.</small>
                  <div className="sign-in">
                    <button
                      className="btn btn-all btn-pink"
                      onClick={handleVerifyOTP}
                    >
                      Verify
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginOrRegisterModal;
