// const baseURL = 'https://www.bigsocial.in/api';
const baseURL = 'https://backend.bigsocial.in/api';

export const userURLs = {
  getCategories: `${baseURL}/category-list`,
  login: `${baseURL}/customer/login`,
  verifyOTP: `${baseURL}/customer/verifyOTP`,
  transactions: `${baseURL}/customer/wallet/transactions`,
  orders: `${baseURL}/customer/order/get`,
  wishlist: `${baseURL}/customer/wishlist/get`,
  addToWishlist: `${baseURL}/customer/wishlist/add`,
  requestAnAppointment: `${baseURL}/customer/request-appointment`,
  makePayment: `${baseURL}/customer/payment/send-credit`,
  walletBalance: `${baseURL}/customer/wallet/balance`,
  giftCredit: `${baseURL}/customer/gift/credit`,
  cancelOrder: `${baseURL}/customer/order/cancel`,
  updateProfile: `${baseURL}/customer/profile/update`,
  payment: `${baseURL}/customer/order/payment`,
  paymentStatus: `${baseURL}/customer/order/payment/status`,
  raiseAConcern: `${baseURL}/customer/order/concern-request`,
  requestAQuote: `${baseURL}/offer/request/quote`,
  downloadInvoice: `${baseURL}/customer/download/invoice`,
  uploadProfilePicture: `${baseURL}/upload-image`,
  downloadVoucher: `${baseURL}/customer/vourcher-pdf`,
  registerACallback: `${baseURL}/customer/order/callback-request`,
  checkUserExistOrNot: `${baseURL}/customer/validate-mobile-number`,
  merchantInfo: `${baseURL}/customer/get-business`,
  notifyAvailability: `${baseURL}/offer/notify`,
  removeVoucher: `${baseURL}/customer/order/hide`,
  registerBusiness: `${baseURL}/request/merchant`,
};

export const generalURLs = {
  topBanner: `${baseURL}/top-banner`,
  discountFilter: `${baseURL}/filter/discount`,
  cityList: `${baseURL}/city-list`,
  areaList: `${baseURL}/areas/list`,
  distanceFilter: `${baseURL}/filter/distance`,
  budgetFilter: `${baseURL}/budget/filter`,
  savingFilter: `${baseURL}/filter/savings`,
  categoryOffers: `${baseURL}/category-offers`,
  subCategoryOffers: `${baseURL}/category-offers`,
  offerDetails: `${baseURL}/offer-details`,
  merchantDetails: `${baseURL}/merchant-details`,
  merchantAllOffer: `${baseURL}/merchant-offers`,
  search: `${baseURL}/search`,
  allMerchant: `${baseURL}/merchants/list`,
  dashboard: `${baseURL}/dashboard`,
  filterSubClass: `${baseURL}/filter/subclass`,
  statesList: `${baseURL}/state-list`,
  termsAndFAQs: `${baseURL}/common-api`,
  merchanttermsAndFAQs: `${baseURL}/vendor-faqs`,
  citiesListForFilters: `${baseURL}/city-area-list`,
  getSuperCardRequest: `${baseURL}/response-super-card`,
  getLeadCardRequest: `${baseURL}/response-lead-card`,

};
