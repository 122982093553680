import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AreaListResponseType,
  CityListResponseType,
} from '../../APIs/generalAPIs/generalAPIsTypes';
import { RootState } from '../store';

export type CustomAreaListType = {
  [key: number]: AreaListResponseType['Result']['area_list'];
};

type InitialStateTypes = {
  cityList?: CityListResponseType;
  cityId?: number;
  areaList: CustomAreaListType;
};

const savedCityId = localStorage.getItem('cityId');

// Define the initial state using that type
const initialState: InitialStateTypes = {
  cityList: undefined,
  cityId: savedCityId ? parseInt(savedCityId) : 0,
  areaList: {},
};

export const citiesSlice = createSlice({
  name: 'cities',
  initialState,
  reducers: {
    setCities: (state, action: PayloadAction<CityListResponseType>) => {
      state.cityList = action.payload;
    },
    setCityId: (state, action: PayloadAction<number>) => {
      localStorage.setItem('cityId', `${action.payload}`);
      state.cityId = action.payload;
    },
    setAreaList: (
      state,
      action: PayloadAction<{
        data: AreaListResponseType['Result']['area_list'];
        cityId: number;
      }>
    ) => {
      const newList: CustomAreaListType = {};
      // copying previous data
      for (const key of Object.keys(state.areaList)) {
        const newArrayObject = [];
        for (const areaInfo of state.areaList[parseInt(key)]) {
          newArrayObject.push({ ...areaInfo });
        }
        newList[parseInt(key)] = newArrayObject;
      }
      // inserting new data
      newList[action.payload.cityId] = action.payload.data;
      // updating store state
      state.areaList = newList;
    },
  },
});

export const { setCities, setCityId, setAreaList } = citiesSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCities = (state: RootState) => state.cities.cityList;
export const selectCityId = (state: RootState) => state.cities.cityId;
export const selectAreaList = (state: RootState) => state.cities.areaList;

export default citiesSlice.reducer;
