import React from "react";

interface Props {}

const RegisterAnExpert = (props: Props) => {
  return (
    <div className="footer_item">
      <h4>Register as an Expert</h4>
      <ul className="link_outer">
        <li>
          <a href="/coming-soon">Sign in as an Expert</a>
        </li>
        <li>
          <a href="/coming-soon">Register as an Expert</a>
        </li>
        <li>
          <a href="/coming-soon">Opportunities as an Expert</a>
        </li>
        <li>
          <a href="/coming-soon">Benefits Program</a>
        </li>
        <li>
          <a href="/coming-soon">Lead Generation</a>
        </li>
        <li>
          <a href="/coming-soon">Awards & Recognitions</a>
        </li>
      </ul>
      <div className="get_touch">
        <h4>Connect With Us</h4>
        <ul className="socail_link d-align">
          <li>
            <a href="https://www.facebook.com/bigsocialindia" target={"_blank"}>
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/images/icon-facebook.png"
                }
                alt=""
              />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/Bigsocial_India" target={"_blank"}>
              <img
                src={process.env.PUBLIC_URL + "/assets/images/icon-twiiter.png"}
                alt=""
              />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/bigsocialIndia/"
              target={"_blank"}
            >
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/images/icon-instagram.png"
                }
                alt=""
              />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/bigsocialindia"
              target={"_blank"}
            >
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/images/icon-linkedin.png"
                }
                alt=""
              />
            </a>
          </li>
        </ul>
      </div>
      <div className="get_touch app-store">
        <h4>Get Android App</h4>
        <ul className="socail_link d-align">
          <li>
            <a
              href="https://play.google.com/store/apps/details?id=com.bigsocial.app"
              target={"_blank"}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/google_appstore_img.png"
                }
                alt=""
                width={200}
                height={50}
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default RegisterAnExpert;
