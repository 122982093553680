import React from "react";
import { Link } from "react-router-dom";

interface Props {}

const LearnMore = (props: Props) => {
  return (
    <div className="footer_item">
      <h4>Learn More</h4>
      <ul className="link_outer">
        <li>
          <a href="/how-it-works">How it Works</a>
        </li>
        <li>
          <a href="/redeemable-vouchers">Redeemable Vouchers</a>
        </li>
        <li>
          <a href="/pay-social">PaySocial</a>
        </li>
        <li>
          <a href="/coming-soon">Things to do</a>
        </li>
        <li>
          <Link to="/faqs">FAQs</Link>
        </li>
        <li>
          <a href="/coming-soon">Expert answers at Bigsocial</a>
        </li>
        <li>
          <a href="/request-quotes">Request Quotes</a>
        </li>
        <li>
          <a href="/gifting-credits">Gifting Credits</a>
        </li>
        <li>
          <a href="/coming-soon">Online Consultations</a>
        </li>
        <li>
          <a href="/coming-soon">Online Events</a>
        </li>
        <li>
          <a href="/coming-soon">Experiences</a>
        </li>
        <li>
          <a href="/coming-soon">Blogs</a>
        </li>
        <li>
          <a href="/coming-soon">Corporate Benefits</a>
        </li>
      </ul>
    </div>
  );
};

export default LearnMore;
