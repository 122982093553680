import { UserVerifyOTPResponseType } from './userAPIs/userAPIsTypes';

export const getConfig: RequestInit = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
  },
};

type PostConfigType = <T>(payload: T) => RequestInit;
type PostConfigPrivateType = <T>(
  payload: T,
  user?: UserVerifyOTPResponseType
) => RequestInit;

export const postConfig: PostConfigType = payload => ({
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(payload),
});

export const postConfigPrivateJSONData: PostConfigPrivateType = (
  payload,
  user
) => ({
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${user?.Result.token}`,
  },
  body: JSON.stringify(payload),
});

export const postConfigFormData = (formData: FormData) => ({
  method: 'POST',
  headers: {},
  body: formData,
});

export const postConfigPrivateFormData = (
  formData: FormData,
  user?: UserVerifyOTPResponseType
) => ({
  method: 'POST',
  headers: {
    Authorization: `Bearer ${user?.Result.token}`,
  },
  body: formData,
});
