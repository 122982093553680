import { userURLs } from '../../constants/urls';
import { handleFetchResponse } from '../../helpers/handleFetchResponse';
import {
  postConfig,
  postConfigFormData,
  postConfigPrivateFormData,
  postConfigPrivateJSONData,
} from '../fetchConfigurations';
import {
  CheckUserExistOrNotResponseType,
  CheckUserExistOrNotType,
  DownloadInvoiceResponseType,
  DownloadInvoiceType,
  DownloadVoucherResponseType,
  DownloadVoucherType,
  MerchantInfoResponseType,
  MerchantInfoType,
  NotifyAvailabilityResponseType,
  NotifyAvailabilityType,
  PaymentResponseType,
  PaymentStatusResponseType,
  PaymentStatusType,
  PaymentType,
  RaiseAConcernResponseType,
  RaiseAConcernType,
  RegisterACallbackResponseType,
  RegisterACallbackType,
  RemoveVoucherResponseType,
  RemoveVoucherType,
  RequestAQuoteResponseType,
  RequestAQuoteType,
  UploadProfilePictureResponseType,
  UploadProfilePictureType,
  UserAddToWishlistResponseType,
  UserAddToWishlistType,
  UserCancelOrderResponseType,
  UserCancelOrderType,
  UserCategoryListRequestType,
  UserCategoryListResponseType,
  UserCategoryListType,
  UserGiftCreditResponseType,
  UserGiftCreditType,
  UserLoginResponseType,
  UserLoginType,
  UserMakePaymentResponseType,
  UserMakePaymentType,
  UserOrdersResponseType,
  UserOrdersType,
  UserRequestAnAppointmentResponseType,
  UserRequestAnAppointmentType,
  UserTransactionsResponseType,
  UserTransactionsType,
  UserUpdateProfileResponseType,
  UserUpdateProfileType,
  UserVerifyOTPResponseType,
  UserVerifyOTPType,
  UserWalletBalanceResponseType,
  UserWalletBalanceType,
  UserWishlistResponseType,
  UserWishlistType,
  RegisterBusinessResponseType,
  RegisterBusinessType
} from './userAPIsTypes';

export const getCategories: UserCategoryListType = (
  payload: UserCategoryListRequestType
) =>
  fetch(
    userURLs.getCategories,
    postConfig(payload)
  ).then<UserCategoryListResponseType>(handleFetchResponse);

export const login: UserLoginType = payload =>
  fetch(userURLs.login, postConfig(payload)).then<UserLoginResponseType>(
    handleFetchResponse
  );

export const verifyOTP: UserVerifyOTPType = payload =>
  fetch(
    userURLs.verifyOTP,
    postConfigFormData(payload)
  ).then<UserVerifyOTPResponseType>(handleFetchResponse);

export const getUserTransactions: UserTransactionsType = (payload, user) =>
  fetch(
    userURLs.transactions,
    postConfigPrivateJSONData(payload, user)
  ).then<UserTransactionsResponseType>(handleFetchResponse);

export const getUserOrders: UserOrdersType = (payload, user) =>
  fetch(
    userURLs.orders,
    postConfigPrivateJSONData(payload, user)
  ).then<UserOrdersResponseType>(handleFetchResponse);

export const getUserWishlist: UserWishlistType = (payload, user) =>
  fetch(
    userURLs.wishlist,
    postConfigPrivateJSONData(payload, user)
  ).then<UserWishlistResponseType>(handleFetchResponse);

export const addToWishlist: UserAddToWishlistType = (payload, user) =>
  fetch(
    userURLs.addToWishlist,
    postConfigPrivateJSONData(payload, user)
  ).then<UserAddToWishlistResponseType>(handleFetchResponse);

export const requestAnAppointment: UserRequestAnAppointmentType = (
  payload,
  user
) =>
  fetch(
    userURLs.requestAnAppointment,
    postConfigPrivateJSONData(payload, user)
  ).then<UserRequestAnAppointmentResponseType>(handleFetchResponse);

export const makePayment: UserMakePaymentType = (payload, user) =>
  fetch(
    userURLs.makePayment,
    postConfigPrivateJSONData(payload, user)
  ).then<UserMakePaymentResponseType>(handleFetchResponse);

export const walletBalance: UserWalletBalanceType = (payload, user) =>
  fetch(
    userURLs.walletBalance,
    postConfigPrivateJSONData(payload, user)
  ).then<UserWalletBalanceResponseType>(handleFetchResponse);

export const giftCredit: UserGiftCreditType = (payload, user) =>
  fetch(
    userURLs.giftCredit,
    postConfigPrivateJSONData(payload, user)
  ).then<UserGiftCreditResponseType>(handleFetchResponse);


export const registerBusiness: RegisterBusinessType = (payload) =>
  fetch(
    userURLs.registerBusiness,
    postConfigPrivateJSONData(payload)
  ).then<RegisterBusinessResponseType>(handleFetchResponse);



export const cancelOrder: UserCancelOrderType = (payload, user) =>
  fetch(
    userURLs.cancelOrder,
    postConfigPrivateJSONData(payload, user)
  ).then<UserCancelOrderResponseType>(handleFetchResponse);

export const updateProfile: UserUpdateProfileType = (payload, user) =>
  fetch(
    userURLs.updateProfile,
    postConfigPrivateJSONData(payload, user)
  ).then<UserUpdateProfileResponseType>(handleFetchResponse);

export const payment: PaymentType = (payload, user) =>
  fetch(
    userURLs.payment,
    postConfigPrivateJSONData(payload, user)
  ).then<PaymentResponseType>(handleFetchResponse);

export const paymentStatus: PaymentStatusType = (payload, user) =>
  fetch(
    userURLs.paymentStatus,
    postConfigPrivateJSONData(payload, user)
  ).then<PaymentStatusResponseType>(handleFetchResponse);

export const raiseAConcern: RaiseAConcernType = (payload, user) =>
  fetch(
    userURLs.raiseAConcern,
    postConfigPrivateJSONData(payload, user)
  ).then<RaiseAConcernResponseType>(handleFetchResponse);

export const requestAQuote: RequestAQuoteType = (payload, user) =>
  fetch(
    userURLs.requestAQuote,
    postConfigPrivateJSONData(payload, user)
  ).then<RequestAQuoteResponseType>(handleFetchResponse);

export const downloadInvoice: DownloadInvoiceType = (payload, user) =>
  fetch(
    userURLs.downloadInvoice,
    postConfigPrivateJSONData(payload, user)
  ).then<DownloadInvoiceResponseType>(handleFetchResponse);

export const uploadProfilePicture: UploadProfilePictureType = (payload, user) =>
  fetch(
    userURLs.uploadProfilePicture,
    postConfigPrivateFormData(payload, user)
  ).then<UploadProfilePictureResponseType>(handleFetchResponse);

export const downloadVoucher: DownloadVoucherType = (payload, user) =>
  fetch(
    userURLs.downloadVoucher,
    postConfigPrivateJSONData(payload, user)
  ).then<DownloadVoucherResponseType>(handleFetchResponse);

export const registerACallback: RegisterACallbackType = (payload, user) =>
  fetch(
    userURLs.registerACallback,
    postConfigPrivateJSONData(payload, user)
  ).then<RegisterACallbackResponseType>(handleFetchResponse);

export const checkUserExistOrNot: CheckUserExistOrNotType = (payload, user) =>
  fetch(
    userURLs.checkUserExistOrNot,
    postConfigPrivateJSONData(payload, user)
  ).then<CheckUserExistOrNotResponseType>(handleFetchResponse);

export const merchantInfo: MerchantInfoType = (payload, user) =>
  fetch(
    userURLs.merchantInfo,
    postConfigPrivateJSONData(payload, user)
  ).then<MerchantInfoResponseType>(handleFetchResponse);

export const notifyAvailability: NotifyAvailabilityType = (payload, user) =>
  fetch(
    userURLs.notifyAvailability,
    postConfigPrivateJSONData(payload, user)
  ).then<NotifyAvailabilityResponseType>(handleFetchResponse);

export const removeVoucher: RemoveVoucherType = (payload, user) =>
  fetch(
    userURLs.removeVoucher,
    postConfigPrivateJSONData(payload, user)
  ).then<RemoveVoucherResponseType>(handleFetchResponse);
