import React from 'react';
import Spinner from './spinner/Spinner';

interface Props {}

const Fallback = (props: Props) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner {...{ loading: true, size: 100, primary: true }} />
    </div>
  );
};

export default Fallback;
