import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCategories } from "../helpers/customHooks/useCategories";
import { useCities, useCityId } from "../helpers/customHooks/useCities";
import { useNavigationUIComponents } from "../helpers/customHooks/useNavigationUIComponents";
import { useSearchTerm } from "../helpers/customHooks/useSearchTerm";
import { useUser } from "../helpers/customHooks/useUser";
import { useUserDropdown } from "../helpers/customHooks/useUserDropdown";
import { useMenuDropdown } from "../helpers/customHooks/useMenuDropdown";
import { setSearchTerm } from "../redux/reducers/searchReducer";
import { logout } from "../redux/reducers/userReducer";
import { useAppDispatch } from "../redux/storeCustomHooks";
import LocationModal from "./LocationModal";
import LoginOrRegisterModal from "./LoginOrRegisterModal";

const $ = window.jQuery;

interface Props {}

const Header = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useAppDispatch();

  const user = useUser();
  const searchTerm = useSearchTerm();

  const categoryList = useCategories();
  const cityList = useCities();
  const cityId = useCityId();

  const [signProcess, setSignProcess] = useState<"signIn" | "signUp">("signIn");

  const [cityName, setCityName] = useState<string | undefined>("");

  useEffect(() => {
    setCityName(
      cityList?.Result.cityList?.find((city) => city.id === cityId)?.city
    );
  }, [cityList, cityId]);

  useMenuDropdown(true);
  useUserDropdown(true);
  useNavigationUIComponents(!!categoryList);

  const handleCityChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      localStorage.setItem("cityId", `${value}`);
      // this is intentional, don't use react router
      window.location.href = process.env.PUBLIC_URL + "/";
    },
    []
  );

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const handleSearch = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (searchTerm) {
        navigate(`/search?searchTerm=${searchTerm}`);
      }
    },
    [searchTerm, navigate]
  );

  const handleCategoryClick = useCallback(
    (name: string, index: number) => {
      $(".menu,.pageOverlay").removeClass("active");
      $("body").css("overflow-y", "auto");
      $(".openMenu").removeClass("active");
      //$('.submenu').slideUp();
      navigate(`/category/${name}?categoryIndex=${index}`);
    },
    [navigate]
  );

  const handleSubCategoryClick = useCallback(
    (name: string, categoryId: number, subCategoryId: number) => {
      $(".menu,.pageOverlay").removeClass("active");
      $("body").css("overflow-y", "auto");
      $(".openMenu").removeClass("active");
      //$('.submenu').slideUp();
      // navigate(
      //   `/sub-category?subCategoryName=${name}&categoryId=${categoryId}&subCategoryId=${subCategoryId}`
      // );
      navigate(
        `/sub-category?subCategoryName=${name.replace(
          /&/g,
          "and"
        )}&subCategoryId=${subCategoryId}&categoryId=${categoryId}`
      );
    },
    [navigate]
  );

  const handleMenuClick = useCallback(
    (route: string) => {
      if (user) {
        navigate(route);
      } else {
        setSignProcess("signIn");
        $("#loginRegisterModal").modal("show");
      }
    },
    [navigate, user]
  );
  // slice categoryList to show only 3 categories else show in more button
  const categoryListToShow = categoryList?.Result.categoryList.slice(0, 7);
  const categoryListToShowMore = categoryList?.Result.categoryList.slice(7);

  return (
    <>
      <header>
        <div className="container">
          <div className="header_top">
            <div className="logo d-align">
              <div className="toggleMenu">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <Link to="/" replace={location.pathname === "/"}>
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/logo.png"}
                  alt=""
                />
              </Link>
            </div>
            <div className="h_search_outer">
              <div className="h_location d-align">
                <div className="h_loc_text d-align">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/icon-pin.png"}
                    alt=""
                  />
                  <span>Location</span>
                </div>
                <div className="location_input">
                  <select
                    className="form-control"
                    value={cityId}
                    onChange={handleCityChange}
                  >
                    {cityList?.Result.cityList.map((city) => (
                      <option key={city.id} value={city.id}>
                        {city.city}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="search_box">
                <form onSubmit={handleSearch}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search for Services, Providers, Offers & More"
                    name=""
                    value={searchTerm}
                    onChange={(e) => {
                      dispatch(setSearchTerm(e.target.value));
                    }}
                  />
                  <button type="submit" className="btn btn-yellow-big">
                    Search
                  </button>
                </form>
              </div>
            </div>
            <div className="h_account account_outer">
              <div className="h_account_link">
                <div className="user_icon">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/icon-user.png"
                    }
                    alt=""
                  />
                </div>
                <div className="user_text">
                  <span>
                    Hello, {user?.Result.userDetails.name}
                    {!user && <span>&nbsp;Sign in</span>}
                  </span>
                  <h6>
                    <p>Account</p>
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/icon-down.png"
                      }
                      alt=""
                    />
                  </h6>
                </div>
              </div>
              <div className="userDrop">
                {!user && (
                  <div className="sign-in-container text-center">
                    <button
                      data-toggle="modal"
                      data-target="#loginRegisterModal"
                      className="btn btn-all btn-pink"
                      id="sign-in-button"
                      onClick={() => setSignProcess("signIn")}
                    >
                      Sign In
                    </button>
                    <div>
                      <span className="sign-up">
                        New to Bigsocial?{" "}
                        <span
                          data-toggle="modal"
                          data-target="#loginRegisterModal"
                          onClick={() => setSignProcess("signUp")}
                        >
                          Start Here
                        </span>
                      </span>
                    </div>
                  </div>
                )}
                <ul>
                  <li>
                    <span onClick={() => handleMenuClick("/profile")}>
                      My Profile
                    </span>
                  </li>
                  <li>
                    <span onClick={() => handleMenuClick("/transactions")}>
                      My Transactions
                    </span>
                  </li>
                  <li>
                    <span onClick={() => handleMenuClick("/orders")}>
                      My Vouchers / Orders
                    </span>
                  </li>
                  <li>
                    <span onClick={() => handleMenuClick("/my-credits")}>
                      My Credits
                    </span>
                  </li>
                  <li>
                    <span onClick={() => handleMenuClick("/gift-credits")}>
                      Gift My Credits
                    </span>
                  </li>
                  <li>
                    <span onClick={() => handleMenuClick("/make-payment")}>
                      Make a Payment (Paysocial)
                    </span>
                  </li>
                  <li>
                    <span onClick={() => handleMenuClick("/wishlist")}>
                      My Wishlist
                    </span>
                  </li>
                  {/* <li>
                    <span onClick={() => handleMenuClick('/notifications')}>
                      My Notifications
                    </span>
                  </li> */}
                  <li>
                    <Link to="/terms">Terms Of Use</Link>
                  </li>
                  <li>
                    <Link to="/faqs">FAQs</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  {user && (
                    <li>
                      <span id="logout-button" onClick={handleLogout}>
                        Logout
                      </span>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="location_mobile">
              <a href="#locationModal" data-toggle="modal">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/icon-pin-big.png"
                  }
                  alt=""
                />{" "}
                <span style={{ color: "#3f3f3f" }}>
                  {cityName && cityName.length < 12
                    ? cityName
                    : `${cityName ? cityName.slice(0, 12) + "..." : ""}`}
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="menu">
          <div className="container">
            <div className="menu_list">
              <ul>
                {categoryListToShow?.map((category, index) => {
                  return (
                    <li className="hasmenu" key={category.id}>
                      <span
                        onClick={() =>
                          handleCategoryClick(category.name, index)
                        }
                      >
                        {category.name}
                      </span>
                      <div className="openMenu">
                        <i className="fa fa-angle-down"></i>
                      </div>
                      <div className="submenu">
                        <div className="sub_inner">
                          <div className="subpart">
                            <ul>
                              {category.sub_categories.map(
                                (subCategory, index) => {
                                  if (
                                    Math.ceil(
                                      category.sub_categories.length / 2
                                    ) <= index
                                  ) {
                                    return null;
                                  }
                                  return (
                                    <li key={subCategory.id}>
                                      <span
                                        onClick={() =>
                                          handleSubCategoryClick(
                                            subCategory.name,
                                            category.id,
                                            subCategory.id
                                          )
                                        }
                                      >
                                        {subCategory.name}
                                      </span>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                          <div className="subpart">
                            <ul>
                              {category.sub_categories.map(
                                (subCategory, index) => {
                                  if (
                                    category.sub_categories.length / 2 >
                                    index
                                  ) {
                                    return null;
                                  }
                                  return (
                                    <li key={subCategory.id}>
                                      <span
                                        onClick={() =>
                                          handleSubCategoryClick(
                                            subCategory.name,
                                            category.id,
                                            subCategory.id
                                          )
                                        }
                                      >
                                        {subCategory.name}
                                      </span>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
              {categoryListToShowMore && categoryListToShowMore.length > 0 ? (
                <div
                  className="h_account menu_outer  more-menu-link dropdown"
                  id=""
                >
                  <button
                    className="btn btn-secondary dropdown-toggle h_menu_link"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="user_text">
                      <h6>
                        <p>More</p>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icon-down.png"
                          }
                          alt=""
                        />
                      </h6>
                    </div>
                  </button>
                  <div
                    className="dropdown-menu menuDrop"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <ul>
                      {categoryListToShowMore?.map((category, index) => {
                        return (
                          <li
                            className="accrodian-menu-icon"
                            key={`_more_menu_${index}`}
                          >
                            <a
                              href={`/category/${category.name}?categoryIndex=${
                                7 + index
                              }`}
                            >
                              {category.name}
                            </a>

                            <div id="sub-menu">
                              <ul className="sub-accordian">
                                {category?.sub_categories.map((subCategory) => {
                                  return (
                                    <li key={subCategory.id}>
                                      <a
                                        href={`/sub-category?subCategoryName=${subCategory?.name.replace(
                                          /&/g,
                                          "and"
                                        )}&subCategoryId=${
                                          subCategory.id
                                        }&categoryId=${category.id}`}
                                      >
                                        {subCategory.name}
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="pageOverlay"></div>
      </header>
      <LoginOrRegisterModal {...{ signProcess }} />
      <LocationModal />
    </>
  );
};

export default Header;
