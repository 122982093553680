import { lazy } from 'react';

const TransactionsPage = lazy(() => import('../pages/TransactionsPage'));
const OrdersPage = lazy(() => import('../pages/OrdersPage'));
const WishlistPage = lazy(() => import('../pages/WishlistPage'));
const MyCreditsPage = lazy(() => import('../pages/MyCreditsPage'));
const MakePaymentPage = lazy(() => import('../pages/MakePaymentPage'));
const GiftCreditPage = lazy(() => import('../pages/GiftCreditPage'));
const UserProfilePage = lazy(() => import('../pages/UserProfilePage'));
const CheckOrderStatusPage = lazy(
  () => import('../pages/CheckOrderStatusPage')
);
const PaymentPage = lazy(() => import('../pages/PaymentPage'));

export type PrivateRoutesType = {
  title: string;
  path: string;
  element: JSX.Element;
  redirectPath: string;
}[];

export const privateRoutes: PrivateRoutesType = [
  {
    title: 'Transaction History',
    path: '/transactions',
    element: <TransactionsPage />,
    redirectPath: '/',
  },
  {
    title: 'Orders History',
    path: '/orders',
    element: <OrdersPage />,
    redirectPath: '/',
  },
  {
    title: 'Wishlist',
    path: '/wishlist',
    element: <WishlistPage />,
    redirectPath: '/',
  },
  {
    title: 'My Credits',
    path: '/my-credits',
    element: <MyCreditsPage />,
    redirectPath: '/',
  },
  {
    title: 'Make Payment',
    path: '/make-payment',
    element: <MakePaymentPage />,
    redirectPath: '/',
  },
  {
    title: 'Gift Credit',
    path: '/gift-credits',
    element: <GiftCreditPage />,
    redirectPath: '/',
  },
  {
    title: 'Profile',
    path: '/profile',
    element: <UserProfilePage />,
    redirectPath: '/',
  },
  {
    title: 'Order Status',
    path: '/check-order-status',
    element: <CheckOrderStatusPage />,
    redirectPath: '/',
  },
  {
    title: 'Payment',
    path: '/payment',
    element: <PaymentPage />,
    redirectPath: '/',
  },
];
