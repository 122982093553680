import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserCategoryListResponseType } from '../../APIs/userAPIs/userAPIsTypes';
import { RootState } from '../store';

type InitialStateTypes = {
  data?: UserCategoryListResponseType;
};

// Define the initial state using that type
const initialState: InitialStateTypes = { data: undefined };

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategories: (
      state,
      action: PayloadAction<UserCategoryListResponseType>
    ) => {
      state.data = action.payload;
    },
  },
});

export const { setCategories } = categoriesSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCategories = (state: RootState) => state.categories.data;

export default categoriesSlice.reducer;
