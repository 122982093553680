import { memo } from 'react';

import './spinner.css';

interface Props {
  loading?: boolean;
  size: number;
  children?: any;
  primary?: boolean;
  width?: number;
}

function Spinner({ loading, size, children, primary, width }: Props) {
  if (!loading) return children ? children : null;
  return (
    <div
      style={{
        width,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        className="spinner"
        style={{
          height: size,
          width: size,
          borderTopColor: primary ? '#ca367c' : 'white',
          borderLeftColor: primary ? '#ca367c' : 'white',
          borderBottomColor: primary ? '#ca367c' : 'white',
        }}
      />
    </div>
  );
}

Spinner.defaultProps = {
  size: 19,
};

export default memo(Spinner);
