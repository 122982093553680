import React, { lazy } from "react";

const Home = lazy(() => import("../pages/Home"));
const CategoryPage = lazy(() => import("../pages/CategoryPage"));
const SubCategoryPage = lazy(() => import("../pages/SubCategoryPage"));
const MerchantDetails = lazy(() => import("../pages/MerchantDetails"));
const SearchPage = lazy(() => import("../pages/SearchPage"));
const TermsPage = lazy(() => import("../pages/TermsPage"));
const PageNotFound = lazy(() => import("../pages/PageNotFound"));
const FAQPage = lazy(() => import("../pages/FAQPage"));
const MerchantFAQPage = lazy(() => import("../pages/MerchantFAQPage"));
const PrivacyPage = lazy(() => import("../pages/PrivacyPage"));

const CompanyPage = lazy(() => import("../pages/CompanyPage"));
const ContactUs = lazy(() => import("../pages/ContactUsPage"));
const CustomerSupport = lazy(() => import("../pages/CustomerSupportPage"));
const RefundPolicy = lazy(() => import("../pages/RefundPolicyPage"));

const HowItWorksPage = lazy(() => import("../pages/HowItWorksPage"));
const RedeemableVouchersPage = lazy(
  () => import("../pages/RedeemableVouchersPage")
);
const PaySocialPage = lazy(() => import("../pages/PaySocialPage"));
const RequestQuotesPage = lazy(() => import("../pages/RequestQuotesPage"));
const GiftingCreditsPage = lazy(() => import("../pages/GiftingCreditsPage"));
const ComingSoonPage = lazy(() => import("../pages/ComingSoonPage"));
const MerchantPolicyPage = lazy(() => import("../pages/MerchantPolicyPage"));
const WhyBigsocialPage = lazy(() => import("../pages/WhyBigsocialPage"));
const ManageYourAccountPage = lazy(
  () => import("../pages/ManageYourAccountPage")
);
const GeneralAgreementPage = lazy(
  () => import("../pages/GeneralAgreementPage")
);
const BigsocialCampaignPage = lazy(
  () => import("../pages/BigsocialCampaignPage")
);
const AdvertisePage = lazy(() => import("../pages/AdvertisePage"));
const BusinessTermsPage = lazy(() => import("../pages/BusinessTermsPage"));

const RegisterBusinessPage = lazy(
  () => import("../pages/RegisterBusinessPage")
);

export type PublicRoutesType = {
  title: string;
  path: string;
  element: JSX.Element;
}[];

export const publicRoutes: PublicRoutesType = [
  {
    title: "Bigsocial",
    path: "/",
    element: <Home />,
  },
  {
    title: "BS | Category",
    path: "/category/:categoryName",
    element: <CategoryPage />,
  },
  {
    title: "BS | Sub Category",
    path: "/sub-category",
    element: <SubCategoryPage />,
  },
  {
    title: "BS | Offer Details",
    path: "/offer-details/:offerName",
    element: <MerchantDetails />,
  },
  {
    title: "BS | Merchant Details",
    path: "/merchant-details",
    element: <MerchantDetails />,
  },
  {
    title: "BS | Merchant All Offers",
    path: "/merchant-all-offer",
    element: <MerchantDetails />,
  },
  {
    title: "BS | Search",
    path: "/search",
    element: <SearchPage />,
  },
  {
    title: "BS | Terms",
    path: "/terms",
    element: <TermsPage />,
  },
  {
    title: "BS | FAQs",
    path: "/faqs",
    element: <FAQPage />,
  },
  {
    title: "BS | Merchant FAQs",
    path: "/merchant-faqs",
    element: <MerchantFAQPage />,
  },
  {
    title: "BS | Privacy Policy",
    path: "/privacy-policy",
    element: <PrivacyPage />,
  },
  {
    title: "BS | Company Page",
    path: "/company",
    element: <CompanyPage />,
  },
  {
    title: "BS | Contact Us",
    path: "/contact-us",
    element: <ContactUs />,
  },
  {
    title: "BS | Customer Support",
    path: "/customer-support",
    element: <CustomerSupport />,
  },
  {
    title: "BS | Refund Policy",
    path: "/refund-policy",
    element: <RefundPolicy />,
  },
  {
    title: "BS | How it Works",
    path: "/how-it-works",
    element: <HowItWorksPage />,
  },
  {
    title: "BS | Redeemable Vouchers",
    path: "/redeemable-vouchers",
    element: <RedeemableVouchersPage />,
  },
  {
    title: "BS | Pay Social",
    path: "/pay-social",
    element: <PaySocialPage />,
  },
  {
    title: "BS | Request Quotes",
    path: "/request-quotes",
    element: <RequestQuotesPage />,
  },
  {
    title: "BS | Gifting Credits",
    path: "/gifting-credits",
    element: <GiftingCreditsPage />,
  },
  {
    title: "BS | Merchant Privacy Policy",
    path: "/merchant-privacy-policy",
    element: <MerchantPolicyPage />,
  },
  {
    title: "BS | Terms Of  Business",
    path: "/terms-of-business",
    element: <BusinessTermsPage />,
  },
  {
    title: "BS | Why Bigsocial",
    path: "/why-bigsocial",
    element: <WhyBigsocialPage />,
  },
  {
    title: "BS | Manage Your Account",
    path: "/managing-your-account",
    element: <ManageYourAccountPage />,
  },
  {
    title: "BS | General Agreement",
    path: "/general-agreement",
    element: <GeneralAgreementPage />,
  },
  {
    title: "BS | Bigsocial Campaign",
    path: "/bigsocial-campaign",
    element: <BigsocialCampaignPage />,
  },
  {
    title: "BS | Advertise",
    path: "/advertise",
    element: <AdvertisePage />,
  },
  {
    title: "BS | Coming Soon",
    path: "/coming-soon",
    element: <ComingSoonPage />,
  },
  {
    title: "BS | Register Business",
    path: "/register-business",
    element: <RegisterBusinessPage />,
  },
  {
    title: "Bigsocial",
    path: "*",
    element: <PageNotFound />,
  },
];
