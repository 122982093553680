import { customSwal } from './customSwal';

/** 
 * This is the custom error handler, which sets error to 'true' and loading to 'false' when error occurs
@param setError will be executed with 'true'
@param setLoading will be executed with 'false'
@param hideErrorMessages passing 'true' will hide error messages
@param callback a callback function after error happens
@returns void
*/
export const handleError =
  (
    setError?: React.Dispatch<React.SetStateAction<boolean>>,
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>,
    hideErrorMessages?: boolean,
    callback?: () => void
  ) =>
    (apiError: Error) => {
      if (setError) setError(true);
      if (setLoading) setLoading(false);
      if (!hideErrorMessages) {
        // customSwal('Error', apiError?.message && JSON.parse(apiError.message)?.statusText, 'error');
        // customSwal('Error', 'Something went wrong!', 'error');
      }
      if (callback) callback();
    };
