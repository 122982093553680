import React from 'react';
import { Navigate } from 'react-router-dom';
import { PrivateRoutesType } from '../constants/privateRoutes';
import { useUser } from '../helpers/customHooks/useUser';
import PublicRoute from './PublicRoute';

interface Props {
  data: PrivateRoutesType[number];
}

const PrivateRoute = ({ data }: Props) => {
  const user = useUser();

  return user ? (
    <PublicRoute
      data={{ title: data.title, path: data.path, element: data.element }}
    />
  ) : (
    <Navigate to={data.redirectPath} />
  );
};

export default PrivateRoute;
