import React from 'react';
import AboutBigSocial from './AboutBigSocial';
import LearnMore from './LearnMore';
import Marchants from './Marchants';
import RegisterAnExpert from './RegisterAnExpert';

interface Props {}

const Footer = (props: Props) => {
  return (
    <footer>
      <div className="container">
        <div className="footer_block">
          <AboutBigSocial />
          <LearnMore />
          <Marchants />
          <RegisterAnExpert />
        </div>
      </div>
      <div className="footer_bottom">
        <div className="container">
          <p>Copyright© 2022 RewardPoint Technologies Private Limited | All rights reserved.</p>
        </div>
      </div>
    </footer>
	
  );
};

export default Footer;
