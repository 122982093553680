import React from "react";
import { Link } from "react-router-dom";

interface Props {}

const AboutBigSocial = (props: Props) => {
  return (
    <div className="footer_item">
      <h4>About Bigsocial</h4>
      <ul className="link_outer">
        <li>
          <a href="/company">Company</a>
        </li>
        <li>
          <a href="/contact-us">Contact Us</a>
        </li>
        <li>
          <a href="/customer-support">Customer Support</a>
        </li>

        <li>
          <a href="/refund-policy">Refund Policy</a>
        </li>
        <li>
          <Link to="/terms">Terms of Use</Link>
        </li>
        <li>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </li>
      </ul>
    </div>
  );
};

export default AboutBigSocial;
