import Swal, { SweetAlertIcon } from 'sweetalert2';

interface CustomSwal {
  (title?: string, html?: string, icon?: SweetAlertIcon): void;
}

export const customSwal: CustomSwal = (title, html, icon) => {
  Swal.fire({
    position: 'center',
    title,
    html,
    icon,
    timer: 3000,
    // confirmButtonColor: '#ca367c',
  });
};
