import {
  selectAreaList,
  selectCities,
  selectCityId,
} from '../../redux/reducers/citiesReducer';
import { useAppSelector } from '../../redux/storeCustomHooks';

export const useCities = () => useAppSelector(selectCities);
export const useCityId = () => useAppSelector(selectCityId);
export const useAreaList = () => useAppSelector(selectAreaList);
