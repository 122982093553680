import React, { useCallback } from 'react';
import { useCities, useCityId } from '../helpers/customHooks/useCities';

interface Props {}

const LocationModal = (props: Props) => {
  const cityList = useCities();
  const cityId = useCityId();

  const handleCityChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      localStorage.setItem('cityId', `${value}`);
      // this is intentional, don't use react router
      window.location.href = process.env.PUBLIC_URL + '/';
    },
    []
  );

  return (
    <div className="modal fade" id="locationModal">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <div className="loc_body">
              <button type="button" className="close" data-dismiss="modal">
                <span>&times;</span>
              </button>
              <label>Location</label>
              <div className="loc_input">
                <select
                  className="form-control"
                  value={cityId}
                  onChange={handleCityChange}
                >
                  {cityList?.Result.cityList.map(city => (
                    <option key={city.id} value={city.id}>
                      {city.city}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationModal;
