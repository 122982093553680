import { MessageType, StatusType } from '../APIs/commonTypes';
import { customSwal } from './customSwal';

interface BaseProps {
  Status: StatusType;
  Message?: MessageType;
}

interface HandleResponseInComponentType {
  <T>(
    callback?: (data: T) => void,
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>,
    /**passing 'true' hides error message if API fails */
    hideErrorMessages?: boolean,
    errorCallback?: () => void
  ): (data: T & BaseProps) => void;
}

/**
 * this function shows error message properly
 * in case of error and execute callback with
 * response data if the API call is successful
 */
export const handleResponseInComponent: HandleResponseInComponentType =
  (callback, setLoading, hideErrorMessages, errorCallback) => data => {
    if (setLoading) setLoading(false);
    // call callback functions if success
    if (data.Status === 'success') {
      if (callback) callback(data);
    }
    // handle error
    else {
      if (errorCallback) {
        errorCallback();
      }
      if (hideErrorMessages) {
        return;
      }
      // if message is string, show directly
      if (typeof data.Message === 'string') {
        customSwal('Error', data.Message, 'error');
      }
      // if message is object, process and then show
      else if (typeof data.Message === 'object') {
        let constructedString = '';
        for (const key of Object.keys(data.Message)) {
          for (const stringValue of data.Message[key]) {
            constructedString += stringValue;
          }
          constructedString += '<br/>';
        }
        customSwal('Error', constructedString, 'error');
      }
    }
  };
